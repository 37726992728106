* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background-color: #EFF0F2;
    font-family: Arial, Tahoma;
}

.list {
    width: 980px;
    height: 630px;
    padding: 27px;
    margin: 10px auto;
    background-color: #FFF;
}

.list .heading {
    overflow: hidden;
    margin-bottom: 20px;
}

.list .heading h4 {
    float: left;
}

.list .heading i {
    float: right;
    color: #BEBEBE;
}

.list form {
    margin-bottom: 20px;
}

.list form input[type="search"],
.list form input[type="text"],
.list form input[type="phone"] {
    width: 145px;
    padding: 10px 10px;
    margin-right: 15px;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    border: 0;
    background-color: #F0F1F3;
}

.list form input[type="text"] {
    width: 355px;
}

.list form input[type="phone"] {
    width: 270px;
}

.list form input[type="submit"] {
    padding: 11px 25px;
    border: 0;
    border-radius: 4px;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFF;
    background: linear-gradient(90deg, rgba(9,9,121,1) 0%, rgba(94,18,164,1) 58%);
}

/* Start Main */

.list .main {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid #DDD;
}

.list .main input[type="checkbox"] {
    cursor: pointer;
    margin-right: 5px;
}

.list .main span {
    font-size: 14px;
}

.list .main .invoice {
    margin-right: 25px;
}

.list .main .image {
    margin-right: 50px;
}

.list .main .menu {
    position: relative;
    margin-right: 90px;
}

.list .main .menu i {
    position: absolute;
    top: 1px;
    left: 40px;
}

.list .main .customer {
    position: relative;
    margin-right: 30px;
}

.list .main .customer i {
    position: absolute;
    top: 1px;
    left: 110px;
}

.list .main .quantity {
    margin-right: 35px;
}

.list .main .amount {
    position: relative;
    margin-right: 40px;
}

.list .main .amount i {
    position: absolute;
    top: 1px;
    left: 55px;
}

.list .main .status {
    position: relative;
    margin-right: 40px;
}

.list .main .status i {
    position: absolute;
    top: 1px;
    left: 85px;
}

.list .main .phone {
    margin-right: 30px;
    position: relative;
}

.list .main .phone i {
    position: absolute;
    top: 1px;
    left: 50px;
}

/* End Main */

/* Start item */

.items .item {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 15px;
    border-bottom: 2px solid #e8e8e8;
}

.items .item  span {
    color: #444;
}

.items .item:first-of-type .check {
    position: relative;
    display: block; 
    cursor: pointer; 
    font-size: 18px; 
    margin-bottom: 6px; 
}

.items .item input[type="checkbox"] {
    cursor: pointer;
    margin-right: 10px;
}

.items .item:first-of-type .check .geekmark {
    position: absolute;
    left: 0;
    top: 3px;
    width: 15px;
    height: 16px;
    border-radius: 2px;
    background: linear-gradient(90deg, rgba(9,9,121,1) 0%, rgba(94,18,164,1) 58%);
}

.items .item span {
    color: #9a9a9a;
    font-size: 12px;
    font-weight: bold;
    margin-right: 27px;
}

.items .item img {
    width: 6%;
    margin-right: 28px;
}

.items .item span:nth-of-type(2) {
    margin-right: 56px;
}

.items .item span:nth-of-type(3) {
    margin-right: 80px;
}

.items .item span:nth-of-type(4) {
    width: 34px;
    height: 27px;
    line-height: 27px;
    margin-right: 55px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #DDD;
}

.items .item span:nth-of-type(5) {
    margin-right: 50px;
    font-size: 13px;
}

.items .item button {
    background-color: #36E6C2;
    color: #FFF;
    border: 0;
    padding: 6px 0;
    width: 84px;
    border-radius: 5px;
    margin-right: 35px;
    font-size: 14px;
}

.items .item span:nth-of-type(6) {
    font-size: 13px;
    margin-right: 80px;
}

.items .item i {
    color: #BEBEBE
}

.items .item:nth-of-type(2) span:nth-of-type(2),
.items .item:nth-of-type(5) span:nth-of-type(2) {
    margin-right: 70px;
}

.items .item:nth-of-type(2) span:nth-of-type(3),
.items .item:nth-of-type(5) span:nth-of-type(3) {
    margin-right: 60px;
}

.items .item:nth-of-type(2) button,
.items .item:nth-of-type(5) button {
    background-color: #FFB74D;
    color: #FFF;
    border: 0;
    padding: 6px 0;
    width: 84px;
    border-radius: 5px;
    margin-right: 35px;
}

.items .item:nth-of-type(2) span:nth-of-type(6),
.items .item:nth-of-type(3) span:nth-of-type(6),
.items .item:nth-of-type(4) span:nth-of-type(6),
.items .item:nth-of-type(5) span:nth-of-type(6),
.items .item:nth-of-type(6) span:nth-of-type(6) {
    color: #868686;
}

.items .item:nth-of-type(3) span:nth-of-type(2),
.items .item:nth-of-type(6) span:nth-of-type(2) {
    margin-right: 48px;
}

.items .item:nth-of-type(4) span:nth-of-type(2) {
    margin-right: 51px;
}

.items .item:nth-of-type(3) span:nth-of-type(3),
.items .item:nth-of-type(6) span:nth-of-type(3) {
    margin-right: 43px;
}

.items .item:nth-of-type(4) span:nth-of-type(3) {
    margin-right: 42px;
}

/* End item */



