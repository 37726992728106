.text-bold {
    font-weight: 800;
}

text-color {
    color: #0093c4;
}

/* Main image - left */
.main-img {
    display: flex;
    justify-content: center;
    height: 450px;
    
}

/* Preview images */
.previews img {
    width: 100%;
    height: 140px;
}

.main-description .category {
    text-transform: uppercase;
    color: #0093c4;
}

.main-description .product-title {
    font-size: 2.5rem;
}

.old-price-discount {
    font-weight: 600;
}

.new-price {
    font-size: 2rem;
}

.details-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    color: #757575;
}

.buttons .block {
    margin-right: 5px;
}

.quantity input {
    border-radius: 0;
    height: 40px;

}


.custom-btn {
    text-transform: capitalize;
    background-color: #0093c4;
    color: white;
    width: 150px;
    height: 40px;
    border-radius: 0;
}

.custom-btn:hover {
    background-color: #0093c4 !important;
    font-size: 18px;
    color: white !important;
}

.similar-product img {
    height: 400px;
}

.similar-product {
    text-align: left;
}

.similar-product .title {
    margin: 17px 0px 4px 0px;
}

.similar-product .price {
    font-weight: bold;
}

.questions .icon i {
    font-size: 2rem;
}

.questions-icon {
    font-size: 2rem;
    color: #0093c4;
}


/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

    /* Make preview images responsive  */
    .previews img {
        width: 100%;
        height: auto;
    }

}